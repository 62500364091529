import { Box, CircularProgress, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import Calendar from './Calendar'
import ZDEHeader from './ZDEHeader'
import JournalHeader from './Journal/JournalHeader'
import { IUser } from '../interface/IUser'
import { useSelector } from 'react-redux'

const Dashboard = () => {
    const theme = useTheme()
    const user: IUser = useSelector((state: any) => state.user?.value)

    return (
        <Grid container spacing={0} alignItems="flex-start" justifyContent="space-around">
            <Grid justifyContent="center" display="flex" size={{ sm: 12, md: 8, lg: 3 }} bgcolor={theme.palette.background.default}>
                <Box padding={2}>
                    <Calendar />
                </Box>
            </Grid>
            <Grid size={{ sm: 8, md: 8, lg: 9 }} bgcolor={theme.palette.background.paper} minHeight={'100vh'}>
                {user.worker ? (
                    <Box padding={2}>{user?.worker?.workertype === 'INTERN' ? <ZDEHeader /> : <ZDEHeader />}</Box> //TODO FAK View
                ) : (
                    <Box padding={2} display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                        <CircularProgress color="inherit" />
                    </Box>
                )}
            </Grid>
        </Grid>
    )
}

export default Dashboard
