import axios, { AxiosRequestConfig } from 'axios'
import { Box, Button, Paper, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import SaveIcon from '@mui/icons-material/Save'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ITimecard } from '../interface/ISelectedDate'
import { useMsal } from '@azure/msal-react'
import { ICustomSnackbar } from '../interface/ICustomSnackbar'
import { setAlert } from '../source/slice/AlertSlice'
import { format } from 'date-fns'
import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { ButtonGroup } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { UpdateStoreHelper } from '../source/StoreHelper'
import { ErrorStatusCode } from '../interface/ErrorStatusCode'

const Footer = () => {
    const { t } = useTranslation()
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const { instance, accounts } = useMsal()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [openDialog, setOpenDialog] = useState(false)
    const popoverRef = React.useRef<HTMLDivElement | null>(null)
    const [dialogContentText, setDialogContentText] = useState(t('footer.releaseWithIllnessConfirmation'))

    const isInternal = true // simulation only, should be changed (getting user data to check whether internal or not)

    const [backendModalOpen, setBackendModalOpen] = useState(false)
    const [backendModalMessage, setBackendModalMessage] = useState('')

    type ErrorCode = 101 | 102 | 103 | 9000 | 9001 | 9002 | 9003 | 9004 | 9005

    const customSnackbarMessageSuccess: ICustomSnackbar = {
        message: t('zdeheader.saveSuccess'),
        type: 'success',
    }
    const customSnackbarMessageError: ICustomSnackbar = {
        message: t('zdeheader.saveError'),
        type: 'error',
    }

    const customSnackbarMessageReleaseSuccess: ICustomSnackbar = {
        message: t('zdeheader.releaseSuccess'),
        type: 'success',
    }
    const customSnackbarMessageReleaseError: ICustomSnackbar = {
        message: t('zdeheader.releaseError'),
        type: 'error',
    }

    const handleClick = () => {
        postTimecard()
    }

    const handleReleaseClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const simulateBackendResponse = (code: ErrorCode) => {
        const errorMessages = {
            9000: t('footer.backendErrors.maximumtime') + ' ' + t('footer.backendErrors.missingComment'),
            9001: t('footer.backendErrors.breaktime') + ' ' + t('footer.backendErrors.missingComment'),
            9002: t('footer.backendErrors.dayafter') + ' ' + t('footer.backendErrors.missingComment'),
            9003: t('footer.backendErrors.daybefore') + ' ' + t('footer.backendErrors.missingComment'),
            9004: t('footer.backendErrors.unknownError'),
            9005: t('footer.backendErrors.sundaywork') + ' ' + t('footer.backendErrors.missingComment'),
            101: t('footer.backendErrors.missingFields'),
            102: t('footer.backendErrors.missingComment'),
            103: t('footer.backendErrors.unknownError'),
            // to be extended (more codes)
        }

        const simulateError = true

        if (simulateError) {
            const errorMessage = errorMessages[code] || t('footer.backendErrors.unknownError')
            setBackendModalMessage(`${errorMessage}`)
        } else {
            setBackendModalMessage(t('footer.backendResponse'))
        }
        setBackendModalOpen(true)
    }

    const handleReleaseWithIllness = () => {
        // to be extended (backend API call)
        setOpenDialog(true)
        setAnchorEl(null)
    }

    const handleDialogClose = (confirm: boolean) => {
        if (confirm) {
            releaseTimecardWithIllness()
            //simulateBackendResponse(101); // error message code can be changed
            setOpenDialog(false)
        } else {
            setOpenDialog(false)
        }
    }

    const postTimecard = () => {
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/Timecard`, JSON.stringify(selectedDate), config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageSuccess))
                        } else {
                            dispatch(setAlert(customSnackbarMessageError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const updateDate = (date: string) => {
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                UpdateStoreHelper(format(date, 'yyyy-MM-dd'), accessToken)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const releaseTimecard = () => {
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReleaseTimecard`, null, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReleaseSuccess))
                            if (selectedDate.header?.mserp_timeprofiledate) {
                                updateDate(selectedDate.header?.mserp_timeprofiledate)
                            }
                        } else {
                            dispatch(setAlert(customSnackbarMessageReleaseError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        //simulateBackendResponse(90001)
                        dispatch(setAlert(customSnackbarMessageReleaseError))
                        var err: ErrorStatusCode = error.response.data
                        if (err.VIOLATION_CODE) {
                            const errorCode: ErrorCode = err.VIOLATION_CODE as ErrorCode
                            simulateBackendResponse(errorCode)
                        }
                    })
            })
            .catch((error) => {
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    const releaseTimecardWithIllness = () => {
        const accessTokenRequest = {
            scopes: ['openid', 'profile', 'email', 'user.read'],
            account: accounts[0],
        }
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                let accessToken: string = accessTokenResponse.accessToken
                const config: AxiosRequestConfig = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + accessToken,
                    },
                    params: {
                        date: selectedDate?.header?.mserp_timeprofiledate ? format(selectedDate.header.mserp_timeprofiledate, 'yyyy-MM-dd') : '',
                        /*code: process.env.REACT_APP_BACKEND_URL*/
                    },
                }

                axios
                    .post(`${process.env.REACT_APP_BACKEND_URL}/ReleaseTimecardWithIllness`, null, config)
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setAlert(customSnackbarMessageReleaseSuccess))
                        } else {
                            dispatch(setAlert(customSnackbarMessageReleaseError))
                        }
                        console.log(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
                dispatch(setAlert(customSnackbarMessageError))
            })
    }

    return (
        <Paper
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100vw',
            }}
        >
            <Paper sx={{ bottom: 0 }}>
                {/* <Tooltip title={t('footer.totalamountdescription')}>
                <span>
                    {t('footer.totalamount', {
                        from: format(new Date(new Date().getFullYear(), 0, 1), 'dd.MM.'),
                        to: format(new Date(), 'dd.MM.yyyy'),
                        amount: 40,
                    })}
                </span>
            </Tooltip> */}
                <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" size="medium" onClick={handleClick} sx={{ mx: 1 }} startIcon={<SaveIcon />} disabled={selectedDate?.header?.isTransferred}>
                        {t('footer.save')}
                    </Button>
                    <div ref={popoverRef} style={{ position: 'absolute', top: 0, left: 0 }}></div>
                    <ButtonGroup variant="contained" sx={{ marginRight: '1rem' }}>
                        <Button size="medium" onClick={releaseTimecard} startIcon={<CheckIcon />} disabled={selectedDate?.header?.isTransferred}>
                            {t('footer.release')}
                        </Button>
                        {isInternal && (
                            <Button size="small" onClick={handleReleaseClick} disabled={selectedDate?.header?.isTransferred}>
                                <ArrowDropDownIcon />
                            </Button>
                        )}
                    </ButtonGroup>
                    {isInternal && (
                        <Menu id="release-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem onClick={handleReleaseWithIllness}>{t('footer.releaseWithIllness')}</MenuItem>
                        </Menu>
                    )}
                </Box>
            </Paper>

            <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
                <DialogTitle>{t('footer.releaseWithIllness')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('footer.releaseWithIllnessConfirmation')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(false)} color="primary">
                        {t('footer.cancel')}
                    </Button>
                    <Button onClick={() => handleDialogClose(true)} color="primary">
                        {t('footer.release')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={backendModalOpen} onClose={() => setBackendModalOpen(false)}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <ErrorOutlineIcon color="error" sx={{ marginRight: '8px' }} />
                        <Typography variant="h6" component="div">
                            {t('footer.error')}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{backendModalMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setBackendModalOpen(false)} color="primary">
                        {t('footer.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default Footer
