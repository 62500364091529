import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: {
        header: {},
        lines: [],
    },
}

export const selectedJournalSlice: any = createSlice({
    name: 'SELECTED_JOURNAL',
    initialState,
    reducers: {
        setSelectedJournal: (state: any, action: any) => {
            state.value = action.payload
        },
    },
})

export const { setSelectedJournal } = selectedJournalSlice.actions

export default selectedJournalSlice.reducer
