import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: [],
}

export const orders: any = createSlice({
    name: 'ORDERS',
    initialState,
    reducers: {
        setOrders: (state: any, action: any) => {
            state.value = action.payload
        },
    },
})

export const { setOrders } = orders.actions

export default orders.reducer
